<template>
    <HeaderPanel />
    <ToastVue />
    <div class="form">
        <FormVue :resolver="this.resolver" @submit="onFormSubmit">
            <FormField class="input" v-slot="$field" as="section" name="name" initialValue="">
                <InputText type="text" placeholder="Имя пользователя" />
                <Message v-if="$field?.invalid" severity="error" size="small" variant="simple">{{ $field.error?.message
                    }}</Message>
            </FormField>
            <FormField class="input" v-slot="$field" as="section" name="email" initialValue="">
                <InputText type="text" placeholder="Электронная почта" />
                <Message v-if="$field?.invalid" severity="error" size="small" variant="simple">{{ $field.error?.message
                    }}</Message>
            </FormField>
            <FormField class="input" v-slot="$field" as="section" name="message" initialValue="">
                <!--<InputText type="text" placeholder="Сообщение" />-->
                <Editor v-model="value" editorStyle="height: 320px" />
                <Message v-if="$field?.invalid" severity="error" size="small" variant="simple">{{ $field.error?.message
                    }}</Message>
            </FormField>
            <ButtonVue type="submit" severity="secondary" label="Оставить обращение" />
        </FormVue>
    </div>
</template>

<script>
import { Form as FormVue, FormField } from '@primevue/forms';
import Message from 'primevue/message';
import Editor from 'primevue/editor';
//import { reactive } from 'vue';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';
import axios from 'axios';
import HeaderPanel from '@/components/HeaderPanel.vue';
import { useUserStore } from '@/stores/user';

export default {
    components: {
        FormVue,
        FormField,
        Message,
        Editor,
        HeaderPanel
    },
    data() {
        return {
            userStore: useUserStore(),
            resolver: zodResolver(
                z.object({
                    name: z.string().min(1, { message: 'Username is required.' }),
                    //email: z.string().min(1, { message: 'Password is required.' }),
                    message: z.string().min(1, { message: 'Message is required' }),
                    email: z.string().email({ message: 'Email is required' })
                })
            )
        };
    },
    async mounted() {
        await this.userStore.checkAuth();
    },
    methods: {
        async onFormSubmit({ valid, values }) {
            if (valid) {
                const data = {
                    name: values.name,
                    email: values.email,
                    message: values.message
                }
                try {
                    const response = await axios.post("https://ncht.bz/api/mail/send-mail", data);
                    if (response.status == 200) {
                        console.log('ok');
                        this.$toast.add({ severity: 'success', summary: 'Info', detail: 'Обращение успешно отправлено!', life: 3000 })
                    } else {
                        console.log('not ok');
                    }
                } catch(error) {
                    this.$toast.add({ severity: 'error', summary: 'Info', detail: 'Произошла ошибка! Попробуйте позже.', life: 3000 })
                    console.error(error.message);
                }
            }
        }
    }
};
</script>

<style scoped>
.form {
    display: flex;
    justify-content: center;
    text-align: left;
    width: 40%;
    margin: 10rem auto auto auto;
}

form {
    display: block;
}

.input {
    margin-bottom: 1rem;
}

.element {
    margin: auto;
}
</style>