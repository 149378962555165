<script>
import PasswordVue from 'primevue/password';
//import { reactive } from 'vue';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';

export default {
    components: {
        PasswordVue
    },
    data() {
        return {
            showRegister: false,
            initialValues: {
                username: '',
                email: '',
                password: ''
            },
            resolver: zodResolver(
                z.object({
                    username: z.string().min(1, { message: 'Обязательное поле' }),
                    email: z.string().min(1, { message: 'Обязательное поле' }).email({ message: 'Некорректный адрес' }),
                    password: z.string().min(8, { message: 'Минимум 8 символов' })
                })
            ),
            formData: {
                username: '',
                email: '',
                password: ''
            },
            errors: {
                username: '',
                email: '',
                password: ''
            },
            toast: null
        };
    },
    setup() {
        const router = useRouter();
        const store = useUserStore();
        return { router, store };
    },
    mounted() {
        if (this.$route.params.rid) {
            this.formData.username = atob(decodeURIComponent(this.$route.params.rid));
            this.showRegister = true;
        } else {
            this.showRegister = false;
        }
        this.toast = this.$toast;
    }, 
    methods: {
        validateForm() {
            this.errors = {
                username: '',
                email: '',
                password: ''
            };

            if (!this.formData.username) {
                this.errors.username = 'Обязательное поле';
            }

            if (this.showRegister) {
                if (!this.formData.email) {
                    this.errors.email = 'Обязательное поле';
                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email)) {
                    this.errors.email = 'Некорректный адрес';
                }
            }

            if (!this.formData.password) {
                this.errors.password = 'Обязательное поле';
            } else if (this.formData.password.length < 8) {
                this.errors.password = 'Минимум 8 символов';
            }

            return !Object.values(this.errors).some(error => error);
        },
        async onSubmit() {
            if (this.validateForm()) {
                if (this.showRegister) {
                    await this.register();
                } else {
                    await this.login();
                }
            }
        },
        async login() {
            try {
                const response = await axios.post("https://ncht.bz/api/auth/login", this.formData);
                if (response.status === 200) {
                    this.store.setToken(response.data.token);
                    this.store.setUser(response.data.user);
                    this.toast.add({ severity: 'success', summary: 'Успешно', detail: 'Вход выполнен', life: 3000 });
                    const redirectPath = this.$route.query.redirect || '/';
                    await this.router.push(redirectPath);
                }
            } catch (error) {
                console.error('Login error:', error);
                this.toast.add({ 
                    severity: 'error', 
                    summary: 'Ошибка', 
                    detail: error.response?.data?.message || 'Ошибка входа', 
                    life: 3000 
                });
            }
        },
        async register() {
            try {
                const response = await axios.post("https://ncht.bz/api/auth/register", this.formData);
                if (response.status === 200) {
                    this.store.setToken(response.data.token);
                    this.store.setUser(response.data.user);
                    this.toast.add({ severity: 'success', summary: 'Успешно', detail: 'Регистрация выполнена', life: 3000 });
                    const redirectPath = this.$route.query.redirect || '/';
                    await this.router.push(redirectPath);
                }
            } catch (error) {
                console.error('Register error:', error);
                this.toast.add({ 
                    severity: 'error', 
                    summary: 'Ошибка', 
                    detail: error.response?.data?.message || 'Ошибка регистрации', 
                    life: 3000 
                });
            }
        }
    }
};
</script>

<template>
    <div class="card">
        <ToastVue />
        <div class="form-container">
            <form @submit.prevent="onSubmit" class="form">
                <div class="form-group">
                    <FloatLabel class="label" variant="on">
                        <InputText 
                            id="username" 
                            v-model="formData.username" 
                            type="text"
                            class="form-control"
                            :disabled="showRegister && formData.username" 
                        />
                        <label for="username">Имя пользователя</label>
                    </FloatLabel>
                    <small class="error-text" v-if="errors.username">{{ errors.username }}</small>
                </div>

                <div v-if="showRegister" class="form-group">
                    <FloatLabel class="label" variant="on">
                        <InputText 
                            id="email" 
                            v-model="formData.email" 
                            type="email"
                            class="form-control" 
                        />
                        <label for="email">Эл. почта</label>
                    </FloatLabel>
                    <small class="error-text" v-if="errors.email">{{ errors.email }}</small>
                </div>

                <div class="form-group">
                    <FloatLabel class="label" variant="on">
                        <PasswordVue 
                            id="password" 
                            v-model="formData.password" 
                            :feedback="false" 
                            toggleMask
                            class="form-control"
                            inputClass="w-full" 
                        />
                        <label for="password">Пароль</label>
                    </FloatLabel>
                    <small class="error-text" v-if="errors.password">{{ errors.password }}</small>
                </div>

                <ButtonVue 
                    type="submit" 
                    :label="showRegister ? 'Зарегистрироваться' : 'Войти'"
                    class="submit-button"
                    severity="secondary"
                />
            </form>
        </div>
    </div>
</template>

<style scoped>
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--surface-ground);
}

.form-container {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-control {
    width: 100%;
}

.error-text {
    color: #ef4444;
    font-size: 0.875rem;
}

.submit-button {
    margin-top: 1rem;
    width: 100%;
}

:deep(.p-password) {
    width: 100%;
}

:deep(.p-password-input) {
    width: 100%;
}
</style>