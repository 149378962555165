import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import HomePage from '@/pages/HomePage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import UtmPage from '@/pages/UtmPage.vue'
import SmsPage from '@/pages/SmsPage.vue'
import AdminPage from '@/pages/AdminPage.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/login/:rid',
      name: 'register',
      component: LoginPage
    },
    {
      path: '/utm',
      name: 'utm',
      component: UtmPage,
      meta: { requiresAuth: true, allowedRoles: ['admin', 'contractor', 'employee'] }
    },
    {
      path: '/utm/:rid',
      name: 'utmid',
      component: UtmPage,
      meta: { requiresAuth: true, allowedRoles: ['admin', 'contractor', 'employee'] }
    },
    {
      path: '/sms',
      name: 'sms',
      component: SmsPage,
      meta: { requiresAuth: true, allowedRoles: ['admin', 'contractor', 'employee'] }
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminPage,
      meta: { requiresAuth: true, allowedRoles: ['admin'] }
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const store = useUserStore()
  
  // Проверяем авторизацию только если роут требует этого
  if (to.meta.requiresAuth) {
    const isAuthenticated = await store.checkAuth()
    
    if (!isAuthenticated) {
      next({ 
        path: '/login', 
        query: { redirect: to.fullPath } 
      })
      return
    }

    // Проверяем роли
    if (to.meta.allowedRoles && !store.hasRole(to.meta.allowedRoles)) {
      next({ 
        path: '/', 
        query: { redirect: to.fullPath } 
      })
      return
    }
  } else if (to.path === '/login' && store.isAuthenticated) {
    // Если пользователь авторизован и пытается зайти на страницу логина
    next({ path: '/' })
    return
  }
  
  next()
})

export default router