import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    isAuthenticated: false,
    token: localStorage.getItem('userToken') || null
  }),

  getters: {
    getUser: (state) => state.user,
    isAdmin: (state) => state.user?.role === 'admin',
    hasRole: (state) => (roles) => roles.includes(state.user?.role)
  },

  actions: {
    async login(username, password) {
      try {
        const response = await axios.post('https://ncht.bz/api/auth/login', {
          username,
          password
        });

        const { token, user } = response.data;
        this.setToken(token);
        this.setUser(user);
        return true;
      } catch (error) {
        console.error('Ошибка при входе:', error);
        this.logout();
        throw error;
      }
    },

    async checkAuth() {
      if (!this.token) {
        this.isAuthenticated = false;
        return false;
      }

      try {
        const response = await axios.get('https://ncht.bz/api/auth/authorization', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        
        if (response.status === 200) {
          this.setUser(response.data);
          return true;
        }
        
        return false;
      } catch (error) {
        console.error("Ошибка при проверке авторизации:", error);
        this.logout();
        return false;
      }
    },

    setUser(userData) {
      this.user = userData;
      this.isAuthenticated = true;
    },

    setToken(token) {
      this.token = token;
      localStorage.setItem('userToken', token);
    },

    logout() {
      this.user = null;
      this.token = null;
      this.isAuthenticated = false;
      localStorage.removeItem('userToken');
    }
  }
}) 