<template>
    <div v-if="userStore.isAuthenticated" class="card">
        <Toolbar>
            <template #start>
                <ButtonVue icon="pi pi-chart-bar" severity="secondary" text label="UTM" @click="$router.push('/utm')" />
                <ButtonVue icon="pi pi-comments" severity="secondary" text label="Рассылки" @click="$router.push('/sms')" />
            </template>

            <!--<template #center>
                <IconField>
                    <InputIcon>
                        <i class="pi pi-search" />
                    </InputIcon>
                    <InputText placeholder="Search" />
                </IconField>
            </template>-->

            <template #end v-if="userStore.user && userStore.user.role === 'admin'">
                <ButtonVue icon="pi pi-cog" severity="secondary" text label="Админка" @click="$router.push('/admin')" />
            </template>
        </Toolbar>
    </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import Toolbar from 'primevue/toolbar';

export default {
    components: { 
        Toolbar
    },
    data() {
        return {
            userStore: useUserStore(),
            items: [
                {
                    label: 'Главная',
                    icon: 'pi pi-home'
                },
                {
                    label: 'Features',
                    icon: 'pi pi-star'
                },
                {
                    label: 'Projects',
                    icon: 'pi pi-search',
                    items: [
                        {
                            label: 'Components',
                            icon: 'pi pi-bolt'
                        },
                        {
                            label: 'Blocks',
                            icon: 'pi pi-server'
                        },
                        {
                            label: 'UI Kit',
                            icon: 'pi pi-pencil'
                        },
                        {
                            label: 'Templates',
                            icon: 'pi pi-palette',
                            items: [
                                {
                                    label: 'Apollo',
                                    icon: 'pi pi-palette'
                                },
                                {
                                    label: 'Ultima',
                                    icon: 'pi pi-palette'
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Contact',
                    icon: 'pi pi-envelope'
                }
            ]
        };
    }
};
</script>

<style scoped>
.card {
    margin-bottom: 1rem;
}
</style>
