<script>
import axios from 'axios';

    export default {
        
        data() {
            return {
                text1: 'dsdfw',
                short_url: '',
                domain: '',
                test: [{name: 'ncht.bz'}],
                inputData: [
                    {
                        id: '',
                        type: 'select',
                        data: [{name: 'ncht.bz'}],
                        name: 'Домен',
                        icon: 'M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5ZM6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0Z'
                    },
                    {
                        id: '',
                        type: 'text',
                        data: '',
                        name: 'URL',
                        icon: 'M18,2H16.5V1.5h-2v3h2V4H18V5a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1ZM5,1V6H3.5V4.5h-2V6H0V1A1,1,0,0,1,1,0H4A1,1,0,0,1,5,1Zm-1.5.5h-2V3h2Zm7,1.5a1,1,0,0,1,1,1V5a1,1,0,0,1-1,1h-4V0h4a1,1,0,0,1,1,1V2A1,1,0,0,1,10.5,3ZM8,1.5v.75h2V1.5Zm2,2.25H8V4.5h2Z'
                    },
                    {
                        id: '',
                        type: 'select',
                        data: '',
                        name: 'Пространство',
                        icon: 'M4,12a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4A4,4,0,0,0,4,10Zm6-8A2,2,0,1,1,8,4a2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4A4,4,0,0,0,10,0Zm6,12a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4A4,4,0,0,0,16,10Z'
                    },
                    {
                        id: '',
                        type: 'text',
                        data: '',
                        name: 'Пароль',
                        icon: 'M14,7H13V5A5,5,0,0,0,3,5V7H2A2,2,0,0,0,0,9V19a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V9A2,2,0,0,0,14,7ZM5,5a3,3,0,0,1,6,0V7H5Zm9,14H2V9H14ZM8,16a2,2,0,1,0-2-2A2,2,0,0,0,8,16Z'
                    },
                    {
                        id: '',
                        type: 'date',
                        data: '',
                        name: 'Дата действия',
                        icon: 'M4,9H6v2H4ZM18,4V18a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2h8V0h2V2h1A2,2,0,0,1,18,4ZM2,6H16V4H2ZM16,18V8H2V18Zm-4-7h2V9H12ZM8,11h2V9H8Z'
                    },
                    {
                        id: '',
                        type: 'date',
                        data: '',
                        name: 'Срок действия ссылки',
                        icon: 'M4,9H6v2H4ZM18,4V18a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2h8V0h2V2h1A2,2,0,0,1,18,4ZM2,6H16V4H2ZM16,18V8H2V18Zm-4-7h2V9H12ZM8,11h2V9H8Z'
                    },
                    {
                        id: '',
                        type: 'text',
                        data: '',
                        name: 'Клики',
                        icon: 'M16,7.93a8,8,0,0,0-16,0v6a8,8,0,0,0,16,0Zm-2,0H9V2.09A6,6,0,0,1,14,7.93ZM7,2.09V7.93H2A6,6,0,0,1,7,2.09Zm7,11.84a6,6,0,0,1-12,0v-4H14Z'
                    },
                    {
                        id: '',
                        type: 'select',
                        data: '',
                        name: 'Отключено',
                        icon: 'M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0ZM2,10a8,8,0,0,1,8-8,7.9,7.9,0,0,1,4.9,1.69L3.69,14.9A7.9,7.9,0,0,1,2,10Zm8,8a7.9,7.9,0,0,1-4.9-1.69L16.31,5.1A7.9,7.9,0,0,1,18,10,8,8,0,0,1,10,18Z'
                    }
                ]
            }
        },
        methods: {
            async getLink() {
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer pmD0cEaG3lskuCXcf7dzkYCBkoP2Dvm4vjyxBmfWBmmQgGgnlDJdf3KHqyrfos74'
                    }
                }

                let alias = this.generateAlias();
                
                while (this.checkAlias(alias) == false) {
                    alias = this.generateAlias( );
                }
                //console.log(alias);
                const data = {
                    "url": "http://localhost:51734",
                    "domain_id": 2,
                    "alias": alias
                }

                const response = await axios.post('https://url-click.ru/api/v1/links', data, config);
                console.log(response.data.data.short_url);
                this.$emit('handleShortLink', this.short_url);
                //this.short_url = response.data.data.short_url
            },
            async checkAlias(alias) {
                try {
                    const config = {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer pmD0cEaG3lskuCXcf7dzkYCBkoP2Dvm4vjyxBmfWBmmQgGgnlDJdf3KHqyrfos74'
                        }
                    };
                    const response = await axios.get('https://url-click.ru/api/v1/links', config);
                    //console.log(response.data);
                    return response.data.data.some(element => alias === element.alias);
                } catch(error) {
                    console.error("Ошибка: ", error);
                    return false;
                }
            },
            generateAlias() {
                const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
                return chars.charAt(Math.floor(Math.random() * chars.length)) +
                    chars.charAt(Math.floor(Math.random() * chars.length));
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<template>
    <div class="modal">
        <div class="modal-content">
            <h2>Сокращатель ссылок</h2>
            <div class="input-group" v-for="item in inputData" :key="item">
                <div>{{ item.name }}</div>
                <InputGroup>
                    <InputGroupAddon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path :d="item.icon"/>
                            </g>
                        </svg>
                        <!--<i class="pi pi-globe"></i>-->
                    </InputGroupAddon>
                    <InputText v-if="item.type === 'text'"/>
                    <DatePicker v-else-if="item.type === 'date'" v-model="date" />
                    <SelectVue v-else-if="item.type === 'select'" v-model="domain" :options="item.data" optionLabel="name" placeholder="Select a City" />
                </InputGroup>
            </div>
            <ButtonVue @click="getLink()" label="Сократить" />
            <ButtonVue class="close-button" @click="close" label="Закрыть" />
        </div>
    </div>
</template>

<style scoped>
.input-group {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.input-group button {
    flex: 0 0 auto;
    width: 130px;
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 7px 0 0 7px;
    z-index: 15;
}

.input-group input {
    flex: 1 1 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0 7px 7px 0;
    margin-left: -1px;
    z-index: 10;
}

/* Стили для самого модального окна */
.modal {
    display: flex; /* По умолчанию скрыто */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачная темная подложка */
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Убедимся, что окно поверх всего остального */
}

/* Стили для содержимого модального окна */
.modal-content {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 80%; /* Адаптивная ширина */
    /*max-width: 600px; /* Максимальная ширина */
}

/* Кнопка закрытия */
.close-button {
    float: right;
}

.close-button:hover {
    color: #a3a3a3;
}
</style>