import './assets/styles/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import Aura from '@primeuix/themes/aura';
import 'primeicons/primeicons.css';

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import { definePreset } from '@primeuix/themes';
import {InputGroup} from 'primevue';
import {InputText} from 'primevue';
import {InputGroupAddon} from 'primevue';
import {RadioButton } from 'primevue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import ColumnTable from 'primevue/column';
import SelectButton from 'primevue/selectbutton';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import Toast from 'primevue/toast';
import Password from 'primevue/password';
import FloatLabel from 'primevue/floatlabel';
import Message from 'primevue/message';
import { Form } from '@primevue/forms';
import { FormField } from '@primevue/forms';
import ToggleSwitch from 'primevue/toggleswitch';
import ContextMenu from 'primevue/contextmenu';
import Popover from 'primevue/popover';


// Импорт стилей
/*import '@primeuix/themes/aura/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/assets/styles/main.css'*/

const Noir = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{zinc.50}',
            100: '{zinc.100}',
            200: '{zinc.200}',
            300: '{zinc.300}',
            400: '{zinc.400}',
            500: '{zinc.500}',
            600: '{zinc.600}',
            700: '{zinc.700}',
            800: '{zinc.800}',
            900: '{zinc.900}',
            950: '{zinc.950}'
        },
        colorScheme: {
            light: {
                primary: {
                    color: '{zinc.950}',
                    inverseColor: '#ffffff',
                    hoverColor: '{zinc.900}',
                    activeColor: '{zinc.800}'
                },
                highlight: {
                    background: '{zinc.950}',
                    focusBackground: '{zinc.700}',
                    color: '#ffffff',
                    focusColor: '#ffffff'
                }
            },
            dark: {
                primary: {
                    color: '{zinc.50}',
                    inverseColor: '{zinc.950}',
                    hoverColor: '{zinc.100}',
                    activeColor: '{zinc.200}'
                },
                highlight: {
                    background: 'rgba(250, 250, 250, .16)',
                    focusBackground: 'rgba(250, 250, 250, .24)',
                    color: 'rgba(255,255,255,.87)',
                    focusColor: 'rgba(255,255,255,.87)'
                }
            }
        }
    }
});

const app = createApp(App);
const pinia = createPinia();

app.component('InputGroup', InputGroup);
app.component('InputText', InputText);
app.component('RadioButton', RadioButton)
app.component('InputGroupAddon', InputGroupAddon);
app.component('ButtonVue', Button);
app.component('ColumnTable', ColumnTable);
app.component('DataTable', DataTable);
app.component('SelectButton', SelectButton);
app.component('DatePicker', DatePicker);
app.component('SelectVue', Select)
app.component('ToastVue', Toast);
app.component('PasswordVue', Password);
app.component('FloatLabel', FloatLabel);
app.component('MessageVue', Message);
app.component('FormVue', Form);
app.component('FormField', FormField);
app.component('ToggleSwitch', ToggleSwitch);
app.component('ContextMenu', ContextMenu);
app.component('PopoverVue', Popover);

app.use(pinia);
app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: Noir
    },
    ripple: true,
    inputStyle: 'filled'
});
app.use(ToastService);
app.use(ConfirmationService);
app.mount('#app');