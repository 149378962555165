<script>
import axios from 'axios';
import Menu from 'primevue/menu';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';
import HeaderPanel from '@/components/HeaderPanel.vue';

export default {
    components: {
        Menu,
        HeaderPanel
    },
    data() {
        return {
            items: [
                { label: 'Новый пользователь', icon: 'pi pi-user-plus', command: () => this.showRegister = !this.showRegister },
                { label: 'База данных', icon: 'pi pi-database' }
            ],
            roles: null,
            showRegister: false,
            formData: {
                username: '',
                role: null
            },
            errors: {},
            resolver: zodResolver(
                z.object({
                    username: z.string().min(1, { message: 'Username is required.' })
                })
            )
        }
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        async newUser() {
            // Сначала очищаем ошибки
            this.errors = {};
            
            // Проверяем обязательные поля
            if (!this.formData.username) {
                this.errors.username = 'Имя пользователя обязательно';
                return;
            }
            
            if (!this.formData.role) {
                this.errors.role = 'Выберите роль';
                return;
            }

            const data = {
                username: this.formData.username,
                role: this.formData.role.id
            }

            try {
                const response = await axios.post("https://ncht.bz/api/auth/new-user", data);
                if (response.status == 200) {
                    console.log(btoa(this.formData.username));
                    const response = await axios.post("https://ncht.bz/api/mail/send-register-mail", { link: `https://ncht.bz/login/${btoa(this.formData.username)}`, username: this.formData.username } );
                    if (response.status == 200) {
                        this.showToast('Успешно!');
                    } else {
                        this.showToast(response.data.message);
                    }
                } else {
                    this.showToast(response.data.message);
                }
            } catch (error) {
                // Обработка ошибок от сервера
                if (error.response?.data?.errors) {
                    this.errors = error.response.data.errors;
                } else {
                    this.showToast(error.response?.data?.message || 'Произошла ошибка');
                }
                console.error(error);
            }
        },
        showToast(message) {
            this.$toast.add({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
        }
    },
    watch: {
        async showRegister(newVal) {
            if (newVal) {
                const response = await axios.get("https://ncht.bz/api/auth/roles");
                this.roles = response.data;
            }
        }
    }
}
</script>

<template>
    <HeaderPanel />
    <div>
        <ButtonVue type="button" icon="pi pi-ellipsis-v" @click="toggle" aria-haspopup="true"
            aria-controls="overlay_menu" />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>

    <div v-if="showRegister" class="register-form">
        <ToastVue />
        <div class="form-container">
            <form @submit.prevent="newUser" class="form">
                <div class="form-group">
                    <FloatLabel class="label" variant="on">
                        <InputText 
                            id="username" 
                            v-model="formData.username" 
                            type="text" 
                            fluid
                        />
                        <label for="username">Имя пользователя</label>
                    </FloatLabel>
                    <small class="error-text" v-if="errors?.username">{{ errors.username }}</small>
                </div>

                <div class="form-group">
                    <FloatLabel class="label" variant="on">
                        <SelectVue 
                            v-model="formData.role" 
                            :options="roles || []"
                            optionLabel="description"
                            fluid
                        />
                        <label for="role">Роль</label>
                    </FloatLabel>
                    <small class="error-text" v-if="errors?.role">{{ errors.role }}</small>
                </div>

                <ButtonVue type="submit" label="Зарегистрировать" class="submit-button" severity="secondary" />
            </form>
        </div>
    </div>

    <!--<div v-if="showRegister">
        <Form :resolver="this.resolver" @submit="newUser">
            <FormField class="input" v-slot="$field" as="section" name="username" initialValue="">
                <InputText type="text" placeholder="Имя пользователя" />
                <Message v-if="$field?.invalid" severity="error" size="small" variant="simple">{{ $field.error?.message
                    }}</Message>
            </FormField>

            <FormField class="select" v-slot="$field" as="section" name="role" initialValue="">
                <SelectVue v-bind="$field" :options="roles" optionLabel="description" placeholder="Выберите роль"/>
            </FormField>
            <ButtonVue type="submit" severity="secondary" label="Зарегистрировать" />
        </Form>
    </div>-->
</template>

<style scoped>
.register-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-container {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}   

.form-group {
    margin-bottom: 15px;
    width: 100%;
}

.label {
    width: 100%;
}

.submit-button {
    width: 100%;
}

/* Дополнительные стили для корректного отображения SelectVue */
:deep(.p-dropdown) {
    width: 100%;
}

/* Дополнительные стили для корректного отображения InputText */
:deep(.p-inputtext) {
    width: 100%;
}
</style>

